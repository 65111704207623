<template>
  <!--    @hidden="resetForm"-->
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Add New Hotel
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Hotel Name" rules="required">
            <b-form-group label="Hotel Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <!--          <validation-provider-->
          <!--            #default="validationContext"-->
          <!--            name="Password"-->
          <!--            rules="required|password"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Password"-->
          <!--              label-for="password"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="password"-->
          <!--                v-model="userData.password"-->
          <!--                :state="getValidationState(validationContext)"-->
          <!--                trim-->
          <!--              />-->

          <!--              <b-form-invalid-feedback>-->
          <!--                {{ validationContext.errors[0] }}-->
          <!--              </b-form-invalid-feedback>-->
          <!--            </b-form-group>-->
          <!--          </validation-provider>-->

          <!-- mobile -->
          <validation-provider #default="validationContext" name="Mobile" rules="required">
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider #default="validationContext" name="Company" rules="required">
            <b-form-group label="Company" label-for="company">
              <b-form-input
                id="company"
                v-model="userData.company"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Printable Address -->
          <validation-provider
            #default="validationContext"
            name="Printable Address"
            rules="required"
          >
            <b-form-group label="Printable Address" label-for="address">
              <b-form-input
                id="address"
                v-model="address.printable"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address Line 1 -->
          <validation-provider
            #default="validationContext"
            name="address-line1"
            rules="required"
          >
            <b-form-group label="Address Line 1" label-for="address-line1">
              <b-form-input
                id="address-line1"
                v-model="address.address_line1"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address Line - 2 -->
          <validation-provider #default="validationContext" name="Address Line - 2">
            <b-form-group label="Address Line - 2" label-for="address-line2">
              <b-form-input
                id="address-line2"
                v-model="address.address_line2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider #default="validationContext" name="City" rules="required">
            <b-form-group label="City" label-for="city">
              <b-form-input
                id="city"
                v-model="address.city"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- State -->
          <validation-provider #default="validationContext" name="State" rules="required">
            <b-form-group label="State" label-for="state">
              <b-form-input
                id="state"
                v-model="address.state"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Zip -->
          <validation-provider #default="validationContext" name="Zip" rules="required">
            <b-form-group label="Zip" label-for="zip">
              <b-form-input
                id="zip"
                v-model="address.zip"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider #default="validationContext" name="Country" rules="required">
            <b-form-group
              label="Country"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="address.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Timezone -->
          <validation-provider #default="validationContext" name="Timezone" rules="required">
            <b-form-group
              label="Timezone"
              label-for="timezone"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.timezone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tzInts"
                :reduce="val => val.value"
                :clearable="false"
                input-id="timezone"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <!--          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->

          <!-- Hotel Status -->
          <validation-provider
            #default="validationContext"
            name="Hotel status"
            rules="required"
          >
            <b-form-group
              label="Hotel status"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <!--          <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.currentPlan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->

          <!-- Plan Validity -->
          <!--          <validation-provider
            #default="validationContext"
            name="Plan Validity"
            rules="required"
          >
            <b-form-group
              label="Plan Validity"
              label-for="planValidity"
            >
              <b-form-datepicker
                id="planValidity"
                v-model="userData.planValidity"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>-->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="adding.isAdding" style="margin:1px 3px" small />
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable no-console */
import {
  BSidebar,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BSidebar,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries: [
        'United Arab Emirates',
        'United Kingdom',
        'USA',
        'India',
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        "Côte d'Ivoire",
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czechia',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Holy See',
        'Honduras',
        'Hungary',
        'Iceland',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Korea',
        'North Macedonia',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine State',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Sweden',
        'Switzerland',
        'Syria',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ],
      tzInts: [
        {
          label: '(GMT-12:00) International Date Line West',
          value: 'Etc/GMT+12',
        },
        { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
        { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
        { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
        {
          label: '(GMT-08:00) Pacific Time (US & Canada)',
          value: 'America/Los_Angeles',
        },
        {
          label: '(GMT-08:00) Tijuana, Baja California',
          value: 'America/Tijuana',
        },
        { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
        {
          label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
          value: 'America/Chihuahua',
        },
        {
          label: '(GMT-07:00) Mountain Time (US & Canada)',
          value: 'US/Mountain',
        },
        { label: '(GMT-06:00) Central America', value: 'America/Managua' },
        {
          label: '(GMT-06:00) Central Time (US & Canada)',
          value: 'US/Central',
        },
        {
          label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
          value: 'America/Mexico_City',
        },
        { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
        {
          label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
          value: 'America/Bogota',
        },
        {
          label: '(GMT-05:00) Eastern Time (US & Canada)',
          value: 'US/Eastern',
        },
        { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
        {
          label: '(GMT-04:00) Atlantic Time (Canada)',
          value: 'Canada/Atlantic',
        },
        { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
        { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
        { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
        { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
        { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
        {
          label: '(GMT-03:00) Buenos Aires, Georgetown',
          value: 'America/Argentina/Buenos_Aires',
        },
        { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
        { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
        { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
        { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
        { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
        {
          label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
          value: 'Africa/Casablanca',
        },
        {
          label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
          value: 'Etc/Greenwich',
        },
        {
          label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
          value: 'Europe/Amsterdam',
        },
        {
          label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
          value: 'Europe/Belgrade',
        },
        {
          label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
          value: 'Europe/Brussels',
        },
        {
          label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
          value: 'Europe/Sarajevo',
        },
        { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
        { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
        {
          label: '(GMT+02:00) Athens, Bucharest, Istanbul',
          value: 'Europe/Athens',
        },
        { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
        { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
        { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
        {
          label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
          value: 'Europe/Helsinki',
        },
        { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
        { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
        { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
        { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
        {
          label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
          value: 'Europe/Moscow',
        },
        { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
        { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
        { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
        { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
        { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
        { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
        { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
        { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
        {
          label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
          value: 'Asia/Karachi',
        },
        {
          label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
          value: 'Asia/Calcutta',
        },
        { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
        { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
        { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
        { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
        { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
        { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
        { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
        {
          label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
          value: 'Asia/Hong_Kong',
        },
        {
          label: '(GMT+08:00) Kuala Lumpur, Singapore',
          value: 'Asia/Kuala_Lumpur',
        },
        { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
        { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
        { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
        { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
        { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
        { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
        { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
        { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
        { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
        {
          label: '(GMT+10:00) Canberra, Melbourne, Sydney',
          value: 'Australia/Canberra',
        },
        { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
        { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
        { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
        {
          label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
          value: 'Asia/Magadan',
        },
        {
          label: '(GMT+12:00) Auckland, Wellington',
          value: 'Pacific/Auckland',
        },
        {
          label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
          value: 'Pacific/Fiji',
        },
        { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
      ],
    }
  },
  setup(props, { emit }) {
    const address = ref({
      printable: null,
      address_line1: null,
      address_line2: null,
      city: null,
      state: null,
      country: 'USA',
      zip: null,
    })
    const blankUserData = {
      name: '',
      email: '',
      currentPlan: 'basic',
      planValidity: new Date().toISOString().slice(0, 10),
      company: '',
      address: '',
      timezone: 'US/Eastern',
      mobile: '',
      status: 'active',
      password: 'WildDefault@123',
    }

    // Use toast
    const toast = useToast()
    const adding = ref({ isAdding: false })

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const showToast = (title, icon, variant, text) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    }
    const onSubmit = () => {
      adding.value.isAdding = true
      userData.value.address = address.value
      store
        .dispatch('app-user/addUser', userData.value)
        .then(res => {
          adding.value.isAdding = false
          if (res.data.type === 'error') {
            showToast('Error adding user', 'AlertTriangleIcon', 'danger', res.data.display)
          } else {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            showToast('Successfully Added user', 'CheckIcon', 'success')
          }
        })
        .catch(e => {
          adding.value.isAdding = false
          showToast(
            'Error adding user, please try again...',
            'AlertTriangleIcon',
            'danger',
            e.message
          )
        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      adding,
      address,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
